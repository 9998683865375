<template>
  <div v-if="role">
    <CRow>
      <CCol md>
        <div class="mb-3">
          <strong>{{ $t("models.role.logo_rect") }}</strong>
        </div>

        <LogoUpload
          @onSave="(data) => onImageSave('logo_rect', data)"
          @onRemove="() => onImageRemove('logo_rect')"
          :title="$t('role.profile.tab_logos.rectangularTitle')"
          :image="logo_rect"
          ratio="300:155"
        />
      </CCol>
      <CCol md>
        <div class="mb-3">
          <strong>{{ $t("models.role.logo_square") }}</strong>
        </div>

        <LogoUpload
          @onSave="(data) => onImageSave('logo_square', data)"
          @onRemove="() => onImageRemove('logo_square')"
          :title="$t('role.profile.tab_logos.squareLogo')"
          :image="logo_square"
          ratio="1:1"
        />
      </CCol>
    </CRow>

    <CButton color="primary" @click="onSave()">{{
      $t("producer.products.form.btnSave")
    }}</CButton>
  </div>
</template>

<script>
import { GetDataManager } from "../../../ds/index";
import LogoUpload from "./LogoUpload";

export default {
  components: {
    LogoUpload,
  },

  props: ["role"],

  model: {
    prop: "role",
    event: "change",
  },

  data() {
    return {
      logo_rect: this.role ? this.role.logo_rect : null,
      logo_square: this.role ? this.role.logo_square : null,
    };
  },

  watch: {
    role(value) {
      if (value) {
        this.logo_rect = value.logo_rect;
        this.logo_square = value.logo_square;
      }
    },
  },

  methods: {
    onImageSave(field, { imageData }) {
      if (imageData) {
        this[field] = imageData;
      }
    },

    onImageRemove(field) {
      this[field] = null;
    },

    onSave() {
      const dm = GetDataManager("auth_frole", []);

      const params = {
        id: this.$store.state.role.id,
        logo_rect:
          this.logo_rect !== null ? parseInt(this.logo_rect.id, 10) : null,
        logo_square:
          this.logo_square !== null ? parseInt(this.logo_square.id, 10) : null,
      };

      dm.update("", params).then(
        (e) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t("role.data.profileLogo.success"),
            color: "success",
            autohide: true,
          });
          this.$emit("change", e);
        },
        (reason) => {
          this.$store.dispatch("toaster/add", {
            title: this.$t("common.editor.messages.ko.title"),
            text: this.$t("role.finance.invoicesSettings.failed"),
            color: "info",
            autohide: true,
          });
        }
      );
    },
  },
};
</script>
